import ReviewsModalVerTwo from '@components/reviews/components/reviews_modal_ver_two';
import { SearchSortFilter } from '@components/reviews/components/search-sort-filter';
import { useSearchSortFilterReviews } from '@components/reviews/components/search-sort-filter/hooks/use-search-sort-filter-reviews';
import { ReviewPhotosFilmstrip } from '@containers/review-photos/ReviewPhotosFilmstrip';
import { useAppSelector } from '@redux/hooks';
import type { ReviewBuckets, Review as ReviewType } from '@typings/reviews';
import Spinner from '@xo-union/tk-component-spinner';
import { H4 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, memo, useState } from 'react';
import type { Member } from 'types/membership';
import type VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import Review from '../review/Review';
import ReviewsSummary from '../reviews_summary';
import VerifiedReviewsBox from '../verified_reviews_box';
import Styles from './Reviews.scss';
import { ViewMoreReviews } from './components/view_more_reviews';
import { useCloseReviewModal } from './hooks/useCloseReviewModal';
import { useOpenReviewModal } from './hooks/useOpenReviewModal';
import { useSecondaryCTAIndex } from './hooks/useSecondaryCTAIndex';

interface Props {
	amountToRender: number;
	buckets: ReviewBuckets;
	isFetching: boolean;
	isFetchingMore: boolean;
	member: Member | object;
	rating: number;
	reviews: ReviewType[];
	reviewsCount: number;
	totalVendorReviewsCount: number;
	vendorAnalytics?: VendorAnalytics;
	vendorId: string;
	viewMoreOrFetchReviews: () => void;
	className?: string;
}

export const Reviews: FC<Props> = memo(
	({
		amountToRender,
		buckets,
		isFetching,
		isFetchingMore,
		rating,
		reviews,
		reviewsCount,
		totalVendorReviewsCount,
		vendorAnalytics,
		vendorId,
		viewMoreOrFetchReviews,
		className,
	}) => {
		const pageType = useAppSelector((state) => state.page.pageType);
		const [modalIsOpen, setModalIsOpen] = useState(false);
		const openModal = useOpenReviewModal(
			setModalIsOpen,
			pageType,
			vendorAnalytics,
		);
		const closeModal = useCloseReviewModal(setModalIsOpen);
		const secondaryCTAIndex = useSecondaryCTAIndex(reviewsCount, vendorId);
		const { shouldShowText } = useSearchSortFilterReviews();

		const shouldShowScannability = useStorefrontFeatureScannability();

		return (
			<div className={className}>
				{modalIsOpen && (
					<ReviewsModalVerTwo
						isModalOpen={modalIsOpen}
						closeModal={closeModal}
						pageType={pageType}
					/>
				)}
				<div className={Styles.reviewsContainer}>
					<div className={Styles.headerContainer}>
						<H4 id="reviews">Reviews</H4>
						{!!totalVendorReviewsCount && !shouldShowScannability && (
							<VerifiedReviewsBox />
						)}
					</div>
					<div className={Styles.reviewsContent}>
						<ReviewsSummary
							rating={rating}
							buckets={buckets}
							reviewsCount={totalVendorReviewsCount}
							openModal={openModal}
							vendorAnalytics={vendorAnalytics}
							showVerifiedReviewsMessage={!!totalVendorReviewsCount}
						/>
						<ReviewPhotosFilmstrip />
						<div
							className={classNames(Styles.reviewsWrapper, {
								[Styles.filteredReviews]: shouldShowText,
							})}
						>
							{totalVendorReviewsCount > 0 && (
								<div data-testid="review-search-sort-filter">
									<SearchSortFilter />
								</div>
							)}
							<div>
								{isFetching ? (
									<div className={Styles.ssfLoaderContainer}>
										<div className={Styles.ssfLoader}>
											<Spinner />
										</div>
									</div>
								) : (
									<>
										<div
											data-testid="review-items"
											className={Styles.reviewItems}
										>
											{reviews.map((review, index) => (
												<Review
													key={`review-${review.id}`}
													comments={review.comments}
													content={review.content}
													createdDate={review.createdDate}
													rating={review.rating}
													reviewer={{
														firstName: review.reviewer.firstName,
														lastName: review.reviewer.lastName,
													}}
													vendorAnalytics={vendorAnalytics}
													hidden={index >= amountToRender}
													showSecondaryCTA={index === secondaryCTAIndex}
													gallery={review.gallery}
													isTestimonial={review.isTestimonial}
													border={index > 0}
												/>
											))}
										</div>
										<ViewMoreReviews
											isFetchingMore={isFetchingMore}
											amountRendered={amountToRender}
											totalReviewsCount={reviewsCount}
											viewMoreOrFetchReviews={viewMoreOrFetchReviews}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	},
);

export default Reviews;
