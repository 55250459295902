import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Styles from './styles.scss';

export default class ReviewsCount extends PureComponent {
	static propTypes = {
		reviewsCount: PropTypes.number.isRequired,
		showLabel: PropTypes.bool,
	};

	getReviewsText() {
		const { reviewsCount, showLabel } = this.props;
		const shouldPluralize = reviewsCount !== 1;
		let reviewsText = `(${reviewsCount})`;
		if (showLabel) {
			reviewsText = `${reviewsCount} review${shouldPluralize ? 's' : ''}`;
		}
		return reviewsText;
	}

	render() {
		return (
			<span className={Styles.reviewsCount} aria-hidden>
				{this.getReviewsText()}
			</span>
		);
	}
}
