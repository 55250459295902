import React, { useEffect, useState } from 'react';

const fillColors = ['#FF6F00', '#FF44CB', '#FBBB03'];

const getRandomFillColor = () => {
	const randomIndex = Math.floor(Math.random() * fillColors.length);
	return fillColors[randomIndex];
};

const ReviewAvatar = ({ initial }: { initial: string }) => {
	const [fillColor, setFillColor] = useState('#FFFFFF');

	useEffect(() => {
		setFillColor(getRandomFillColor());
	}, []);

	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Avatar</title>
			<circle cx="18" cy="18" r="18" fill={fillColor} />
			<text
				x="50%"
				y="50%"
				textAnchor="middle"
				dy=".3em"
				fontSize="18"
				fill="white"
				fontFamily="union-primary-font"
				fontWeight={800}
			>
				{initial}
			</text>
		</svg>
	);
};

export default ReviewAvatar;
