import { ReadMore } from '@components/read-more/ReadMore';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Body1 } from '@xo-union/tk-ui-typography';
import map from 'lodash/map';
import React, { type FC, useState } from 'react';
import type { Decorated } from 'types/vendor';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import DetailsHelper from '../../helpers';
import { DetailsEmptyState } from '../DetailsEmptyState';
import Awards from '../awards';
import Designers from '../designers';
import Styles from './styles.scss';

interface DetailItemProps {
	category: string;
	details: string[];
	vendor: Decorated;
}
const DetailItem = ({ category, details, vendor }: DetailItemProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();

	return shouldShowScannability ? (
		<DetailItemScannability
			category={category}
			details={details}
			vendor={vendor}
		/>
	) : (
		<DetailItemDefault category={category} details={details} vendor={vendor} />
	);
};

const GuestCapacity = ({
	vendor,
	detail,
}: { vendor: Decorated; detail: string }) => {
	const {
		guestCapacity,
		breadcrumbInfo: { cityUrl },
	} = vendor;

	if (!guestCapacity) {
		return (
			<DetailsEmptyState section="guest capacity" breadcrumbUrl={cityUrl} />
		);
	}

	return <>{guestCapacity}</>;
};

const DetailItemScannability = ({
	category,
	details,
	vendor,
}: DetailItemProps) => {
	return (
		<>
			<Column xs="12" md="4">
				<Body1 bold className={Styles.title}>
					{category}
				</Body1>
			</Column>
			<Column xs="12" md="8">
				<Row className={Styles.list}>
					{details.map((detail: string) => {
						return (
							<Column
								xs={category === 'Guest Capacity' ? '12' : '6'}
								key={`detail_item_${category}_${detail}`}
								className={Styles.itemDetail}
							>
								{category === 'Guest Capacity' ? (
									<GuestCapacity vendor={vendor} detail={detail} />
								) : (
									detail
								)}
							</Column>
						);
					})}
				</Row>
			</Column>
		</>
	);
};

const DetailItemDefault = ({ category, details, vendor }: DetailItemProps) => {
	const [expanded, setExpanded] = useState(false);
	const readMore = details.length > 6;
	if (category === 'Guest Capacity') {
		return (
			<>
				<div className={Styles.detailCategory}>{category}</div>
				<ul className={Styles.list}>
					<li>
						<Body1 className={Styles.detailValue}>{vendor.guestCapacity}</Body1>
					</li>
				</ul>
			</>
		);
	}

	return (
		<>
			<div className={Styles.detailCategory}>{category}</div>
			<ReadMore
				classes={{
					link: Styles.readMoreLink,
					container: Styles.readMoreContainer,
				}}
				expanded={expanded}
				setExpanded={setExpanded}
				showReadMore={readMore}
			>
				<ul className={Styles.list}>
					{details.map((detail: string, index) => {
						if (!expanded && index > 5) {
							return null;
						}

						return (
							<li key={`detail_item_${category}_${detail}`}>
								{category === 'Guest Capacity' ? vendor.guestCapacity : detail}
							</li>
						);
					})}
				</ul>
			</ReadMore>
		</>
	);
};

interface DetailsProps {
	vendor: Decorated;
}

const Details = ({ vendor }: DetailsProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isEmptyState = useStorefrontEmptyStates();

	const flattenedDetails = DetailsHelper.detailsFlattener(vendor);

	if (shouldShowScannability) {
		(flattenedDetails as { 'Business Attributes'?: string })[
			'Business Attributes'
		] = undefined;

		(flattenedDetails as { 'Guest Capacity'?: [string] })['Guest Capacity'] =
			isEmptyState && vendor.categoryCode === 'REC' ? [''] : undefined;
	}

	const facetsLength = Object.keys(flattenedDetails).length;

	if (!facetsLength) {
		return null;
	}

	return (
		<>
			{map(flattenedDetails, (details: string[], category) => {
				if (details !== undefined) {
					return shouldShowScannability ? (
						<>
							<Row className={Styles.detailSection}>
								<DetailItem
									category={category}
									details={details}
									vendor={vendor}
								/>
							</Row>
							<div className={Styles.divider} />
						</>
					) : (
						<Column
							xs="12"
							md="6"
							className={Styles.detailWrapper}
							key={`detail_column_${category}`}
						>
							<DetailItem
								category={category}
								details={details}
								vendor={vendor}
							/>
						</Column>
					);
				}
			})}
		</>
	);
};

interface Props {
	vendor: Decorated;
}

const Detail: FC<Props> = (props) => {
	const { vendor } = props;
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isInDetailedPricing = useDetailedPricing();
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const shouldShowAwards = Boolean(
		vendor.awards.length && !isInDetailedPricing,
	);

	const hasDesigners = Boolean(
		vendor.designers?.length && vendor.designers.length > 0,
	);

	return shouldShowScannability ? (
		<>
			{<Details vendor={vendor} />}
			{hasDesigners && <Designers designers={vendor.designers} />}
			<Row>
				{shouldShowAwards && (
					<Awards vendor={vendor} fullWidth={true} carousel={isMobile} />
				)}
			</Row>
		</>
	) : (
		<Row classes={compose({ row: Styles.detailsRow })}>
			{<Details vendor={vendor} />}
			{hasDesigners && <Designers designers={vendor.designers} />}
			{shouldShowAwards && <Awards vendor={vendor} />}
		</Row>
	);
};

export default Detail;
