import React, { useMemo } from 'react';

type SparkleProps = {
	monochrome?: string;
	size?: string;
};

export const Sparkle = ({ monochrome = '', size = '32' }: SparkleProps) => {
	const fillColor = useMemo(() => {
		if (monochrome === 'black') {
			return ['#000000', '#000000', '#000000'];
		}
		if (monochrome === 'white') {
			return ['#FFFFFF', '#FFFFFF', '#FFFFFF'];
		}
		return ['#A1CDF5', '#FBBB03', '#FF44CB'];
	}, [monochrome]);

	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="sparkle"
		>
			<title>sparkle</title>
			<path
				d="M11.8715 29.5668C10.6292 23.5002 6.63372 18.8002 2.00031 17.1668C6.63372 15.5668 10.6292 10.8335 11.8715 4.76685C13.1138 10.8335 17.1093 15.5668 21.7427 17.1668C17.0757 18.8002 13.0802 23.5002 11.8715 29.5668Z"
				fill={fillColor[0]}
				data-testid="path"
			/>
			<path
				d="M23.858 15.1334C23.1193 11.4667 20.7019 8.63341 17.9151 7.66675C20.7019 6.66675 23.1193 3.83341 23.858 0.166748C24.5966 3.83341 27.0141 6.66675 29.8008 7.63341C27.0141 8.63341 24.5966 11.4667 23.858 15.1334Z"
				fill={fillColor[1]}
			/>
			<path
				d="M23.9179 31.8335C23.3135 28.9335 21.4333 26.6668 19.2173 25.9001C21.4333 25.1335 23.3471 22.8668 23.9179 19.9668C24.5222 22.8668 26.4024 25.1335 28.6184 25.9001C26.4024 26.6668 24.4886 28.9335 23.9179 31.8335Z"
				fill={fillColor[2]}
			/>
		</svg>
	);
};
