import React, { type FC } from 'react';

import Styles from './styles.scss';

const VendorPledge: FC = () => (
	<div className={Styles.pledge}>
		Our venue pledges to create awareness and transparency about the history of
		enslavement at our property for all who visit and celebrate at our site,
		respectfully acknowledge our past and honor the history and contributions of
		African Americans. We have completed The Knot Worldwide Education for
		Change: Plantation Venues program.
	</div>
);

export default VendorPledge;
