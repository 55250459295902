import type {
	ParsedValues,
	RecentlyViewedVendor,
	RecentlyViewedVendorKeys,
	UnparsedValues,
} from '@typings/recentlyViewedVendors';
import { parseISO } from 'date-fns';

const RECENTLY_VIEWED_VENDORS_KEY = 'app:TKMarketplace:RecentlyViewVendors';

const jsonParseReviver = (
	key: RecentlyViewedVendorKeys,
	value: UnparsedValues,
): ParsedValues => {
	if (key === 'dateViewed' && typeof value === 'string') {
		return new Date(value);
	}
	return value;
};

const getVendorsFromLocalStorage = (): null | RecentlyViewedVendor[] => {
	const result = window.localStorage.getItem(RECENTLY_VIEWED_VENDORS_KEY);

	if (result) {
		const parsed: RecentlyViewedVendor[] = JSON.parse(result, jsonParseReviver);
		return parsed;
	}

	return null;
};

const setVendorsToLocalStorage = (vendors: RecentlyViewedVendor[]) => {
	const stringifiedData = JSON.stringify(vendors);
	window.localStorage.setItem(RECENTLY_VIEWED_VENDORS_KEY, stringifiedData);
};

const sortVendors = (vendors: RecentlyViewedVendor[]): RecentlyViewedVendor[] =>
	vendors.sort((a, b) => b.dateViewed.getTime() - a.dateViewed.getTime());

const checkForVendorId = (
	vendors: RecentlyViewedVendor[],
	id: string,
): RecentlyViewedVendor | undefined => {
	const foundVendor = vendors.find((d) => d.storefrontId === id);
	return foundVendor;
};

const updateVendorData = (
	vendors: RecentlyViewedVendor[],
	storefrontId: string,
	leadSent: boolean,
): RecentlyViewedVendor[] => {
	const foundVendor = checkForVendorId(vendors, storefrontId);

	if (foundVendor) {
		const filteredVendors = vendors.filter(
			(d) => d.storefrontId !== storefrontId,
		);

		const updatedVendorData: RecentlyViewedVendor = {
			...foundVendor,
			leadSent,
			dateViewed: parseISO(new Date().toISOString()),
		};

		filteredVendors.unshift(updatedVendorData);
		return filteredVendors;
	}

	const newlySeenVendor: RecentlyViewedVendor = {
		storefrontId,
		leadSent,
		dateViewed: parseISO(new Date().toISOString()),
	};

	vendors.unshift(newlySeenVendor);
	return vendors.slice(0, 50);
};

const orderRecentlySeenVendors = (storefrontId: string, leadSent: boolean) => {
	const localStorageVendorData = getVendorsFromLocalStorage();

	if (localStorageVendorData) {
		const parsedViewedStorefronts = localStorageVendorData;
		const sortedVendors = sortVendors(parsedViewedStorefronts);
		const updatedVendors = updateVendorData(
			sortedVendors,
			storefrontId,
			leadSent,
		);
		setVendorsToLocalStorage(updatedVendors);
		return;
	}

	const initialVendor: RecentlyViewedVendor[] = [
		{
			storefrontId,
			dateViewed: parseISO(new Date().toISOString()),
			leadSent,
		},
	];

	setVendorsToLocalStorage(initialVendor);
};

export {
	RECENTLY_VIEWED_VENDORS_KEY,
	checkForVendorId,
	getVendorsFromLocalStorage,
	jsonParseReviver,
	orderRecentlySeenVendors,
	setVendorsToLocalStorage,
	sortVendors,
	updateVendorData,
};
