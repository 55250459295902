import { Column, Row } from '@xo-union/tk-component-grid';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { difference, map } from 'lodash';
import React, { Component } from 'react';
import VENUE_AMENITIES from '../../constants/venue_amenities';
import DetailsHelper from '../../helpers';

import type { IconName } from '@xo-union/dist-iconography';
import type { VenueAmenity } from 'types/settings';
import type { Decorated } from 'types/vendor';
import { DetailsEmptyState } from '../DetailsEmptyState';
import Styles from './styles.scss';

interface AmenitiesProps {
	vendor: Decorated;
	venueAmenities?: VenueAmenity[];
	shouldShowScannability: boolean;
	shouldShowEmptyState: boolean;
}

export default class Amenities extends Component<AmenitiesProps> {
	renderAmenities = (
		amenities: string[],
		icon: IconName,
		shouldShowScannability?: boolean,
		shouldShowEmptyState?: boolean,
		breadcrumbUrl?: string,
	) => {
		if (!amenities.length && shouldShowEmptyState) {
			return (
				<Column xs="12">
					<DetailsEmptyState
						breadcrumbUrl={breadcrumbUrl}
						section="amenities"
					/>
				</Column>
			);
		}

		return amenities.map((amenity) => (
			<Column
				xs="6"
				md="6"
				key={amenity.toLowerCase()}
				className={Styles.amenitiesWrapper}
			>
				{!shouldShowScannability && (
					<Icon name={icon} className={Styles[icon]} size="md" />
				)}
				<div data-testid={`test-${icon}`} className={Styles.amenity}>
					{amenity}
				</div>
			</Column>
		));
	};

	renderAvailableAmenities(
		amenities: VenueAmenity[],
		shouldShowScannability: boolean,
		shouldShowEmptyState: boolean,
		breadcrumbUrl: string,
	) {
		const amenityNames = map(amenities, 'name');
		return this.renderAmenities(
			amenityNames,
			'checkmark',
			shouldShowScannability,
			shouldShowEmptyState,
			breadcrumbUrl,
		);
	}

	renderRemainingAmenities(vendor: Decorated, shouldShowScannability: boolean) {
		const amenityIds = map(vendor.amenities, 'id');
		const remainingAmenities = difference(VENUE_AMENITIES, amenityIds);
		const remainingAmenityNames = DetailsHelper.findMatchingFacets(
			this.props.venueAmenities || [],
			remainingAmenities,
		);
		return this.renderAmenities(
			remainingAmenityNames,
			'close',
			shouldShowScannability,
		);
	}

	render() {
		const { vendor, shouldShowScannability, shouldShowEmptyState } = this.props;
		if (vendor.categoryCode === 'REC') {
			return (
				<>
					<Row
						className={classNames({
							[Styles.detailSection]: shouldShowScannability,
						})}
					>
						{shouldShowScannability && (
							<Column xs="12" md="4">
								<Body1 bold className={Styles.title}>
									Amenities
								</Body1>
							</Column>
						)}
						<Column
							xs="12"
							md={shouldShowScannability ? '8' : '12'}
							className={Styles.amenities}
						>
							<Row>
								{this.renderAvailableAmenities(
									vendor.amenities,
									shouldShowScannability,
									shouldShowEmptyState,
									vendor.breadcrumbInfo.cityUrl,
								)}
								{!shouldShowScannability &&
									this.renderRemainingAmenities(vendor, shouldShowScannability)}
							</Row>
						</Column>
					</Row>
					{shouldShowScannability && <div className={Styles.divider} />}
				</>
			);
		}

		return false;
	}
}
