import React, { type FC } from 'react';
import type { Decorated } from 'types/vendor';
import VendorHelper from '../../../../../helpers/vendor';
import { isClaimed } from '../../helpers';
import MessageVendorCTA from '../message_vendor_cta/MessageVendorCta';
import TeamInfo from './components/TeamInfo';

interface Props {
	vendor: Decorated;
}

interface InferredAsChildrenOfNavProps {
	title: string;
	shouldHide: boolean;
}

const Bio: FC<Props & Readonly<InferredAsChildrenOfNavProps>> = (props) => {
	const { vendor } = props;
	const { isPaid, claimedStatus } = vendor;
	const shouldShowMessageVendorCta = isPaid && isClaimed(claimedStatus);

	return (
		<div>
			{VendorHelper.bioHasContent(vendor.bio) && <TeamInfo bio={vendor.bio} />}
			{shouldShowMessageVendorCta && (
				<MessageVendorCTA
					size="md"
					label="Message vendor"
					testId="bio-message-vendor-cta"
				/>
			)}
		</div>
	);
};

export default Bio;
