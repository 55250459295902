import React from 'react';
import type { Decorated } from 'types/vendor';
import { NavBar } from './components/NavBar';
import { NavSections } from './components/NavSections';
import type { NavItemElements } from './types';

interface Props {
	children: NavItemElements;
	vendor: Decorated;
}

export const Nav = ({ children, vendor }: Props) => {
	return (
		<>
			<div>
				<NavBar>{children}</NavBar>
				<NavSections vendor={vendor}>{children}</NavSections>
			</div>
		</>
	);
};
