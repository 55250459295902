import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Dispatch, SetStateAction, useCallback } from 'react';
import AnalyticsConstants from '../../../../../../../../constants/analytics';
import type VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

export const useOpenReviewModal = (
	setModalIsOpen: Dispatch<SetStateAction<boolean>>,
	pageType: Page.PageType,
	vendorAnalytics?: VendorAnalytics,
) => {
	const { track } = useAnalyticsContext();
	return useCallback(() => {
		setModalIsOpen(true);
		if (vendorAnalytics) {
			const vendorProps = vendorAnalytics.baseEventProps();
			const paidStatus =
				vendorProps.vendorPurchaseStatus === 'PAID' ? 'paid' : 'unpaid';
			const sourcePage =
				pageType === 'storefront' ? `${paidStatus} storefront` : 'Review Hub';
			track({
				event: AnalyticsConstants.VENDOR_REVIEW_INITIATED,
				properties: { sourcePage, ...vendorProps },
			});
		}
	}, [vendorAnalytics, track]);
};
