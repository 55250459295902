import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { buildAnalyticsEvent } from '@utils/buildAnalyticsEvent';
import classNames from 'classnames';
import PropTypes, { string } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import AnalyticsConstants from '../../../../../../../constants/analytics';
import TrackableLink from '../../../../../../components/trackable_link';
import { useStorefrontFeatureScannability } from '../../../../../Storefront/hooks/useStorefrontFeatureScannability';
import Phone from '../../../InlineRFQ/components/Social/components/Phone';
import Styles from './styles.scss';

const clickThroughTrackingData = (analyticsProperties, props) => {
	const { memberId, vendor } = props;
	return buildAnalyticsEvent({
		analyticsEventType: AnalyticsConstants.CLICK_THROUGH_TO_WEBSITE,
		analyticsProperties: { ...analyticsProperties, memberId },
		vendor,
	});
};

const constructInstagramLink = (instagramUsername) => {
	return instagramUsername.length
		? `http://www.instagram.com/${instagramUsername}`
		: '';
};

const constructPinterestLink = (pinterestUsername) => {
	return pinterestUsername.length
		? `http://www.pinterest.com/${pinterestUsername}`
		: '';
};

const constructTwitterLink = (twitterUsername) => {
	return twitterUsername.length
		? `http://www.twitter.com/${twitterUsername}`
		: '';
};

const PhoneForNonFooterContext = ({ vendor, track, isFooter, goTo }) => {
	if (!vendor.phone || isFooter) {
		return null;
	}

	return (
		<div className={Styles.phone}>
			<Phone track={track} vendor={vendor} goTo={goTo} />
		</div>
	);
};

const websiteLink = (props, useIcon) => {
	const { vendor } = props;

	return (
		<TrackableLink
			websiteUrl={vendor.websiteUrl}
			{...(useIcon ? { icon: 'website' } : { text: 'Website' })}
			title="Website"
			trackingData={clickThroughTrackingData({ destination: 'website' }, props)}
		/>
	);
};

const footerWebsiteLink = (props) => {
	const { vendor } = props;

	return (
		vendor.websiteUrl && (
			<div className={Styles.website}>{websiteLink(props, false)}</div>
		)
	);
};

const Social = (props) => {
	const { isFooter, social, vendor } = props;
	const { track } = useAnalyticsContext();
	const shouldShowScannability = useStorefrontFeatureScannability();

	return (
		<div
			className={classNames({
				[Styles.footer]: isFooter,
			})}
		>
			{isFooter && footerWebsiteLink(props, false)}
			<div className={Styles.socialLinks}>
				<TrackableLink
					websiteUrl={social.facebookUrl}
					icon="social_facebook"
					title="facebook"
					trackingData={clickThroughTrackingData(
						{ destination: 'facebook' },
						props,
					)}
				/>
				<TrackableLink
					websiteUrl={constructTwitterLink(social.twitterUsername)}
					icon="social_twitter"
					title="twitter"
					trackingData={clickThroughTrackingData(
						{ destination: 'twitter' },
						props,
					)}
				/>
				<TrackableLink
					websiteUrl={constructInstagramLink(social.instagramUsername)}
					icon="social_instagram"
					title="instagram"
					trackingData={clickThroughTrackingData(
						{ destination: 'instagram' },
						props,
					)}
				/>
				<TrackableLink
					websiteUrl={constructPinterestLink(social.pinterestUsername)}
					icon="social_pinterest"
					title="pinterest"
					trackingData={clickThroughTrackingData(
						{ destination: 'pinterest' },
						props,
					)}
				/>
				<>
					{!isFooter && websiteLink(props, true)}
					<PhoneForNonFooterContext
						vendor={vendor}
						track={track}
						isFooter={isFooter}
						{...(shouldShowScannability && { goTo: 'contact' })}
					/>
				</>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	memberId: state.membership.member ? state.membership.member.id : '',
});

Social.propTypes = {
	isFooter: PropTypes.bool,
	social: PropTypes.shape({
		twitterUsername: PropTypes.string.isRequired,
		facebookUrl: PropTypes.string.isRequired,
		pinterestUsername: PropTypes.string.isRequired,
		instagramUsername: PropTypes.string.isRequired,
	}).isRequired,
	vendor: PropTypes.shape({
		extension: string,
		phone: string,
		websiteUrl: string,
	}).isRequired,
	memberId: PropTypes.string.isRequired,
};

Social.defaultProps = {
	isFooter: false,
};

export default connect(mapStateToProps)(Social);
