import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { getConversations } from '@redux/messagedVendors/thunks';
import type { CookieStorage } from 'cookie-storage';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Decorated } from 'types/vendor';
import { XO_SESSION_TOKEN } from '../../../../constants/membership';

export const useGetConversions = (cookieStorage: CookieStorage) => {
	const dispatch = useAppDispatch();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const lastVendorNameSent = useAppSelector(
		(state) => state.rfq.lastVendorNameSent,
	);
	const memberId = useAppSelector((state) => state.membership.member?.id || '');

	const getSessionToken = useCallback(() => {
		return cookieStorage.getItem(XO_SESSION_TOKEN) || null;
	}, [cookieStorage]);

	const sessionChangedRef = useRef(getSessionToken());

	// biome-ignore lint/correctness/useExhaustiveDependencies: onMount
	useEffect(() => {
		const sessionToken = getSessionToken();
		if (memberId && sessionToken) {
			dispatch(getConversations(memberId, sessionToken));
		}
	}, []);

	useEffect(() => {
		const autoAccountWithInquiryJustOccurred =
			lastVendorNameSent === vendor.name;
		const sessionToken = getSessionToken();
		if (
			!autoAccountWithInquiryJustOccurred &&
			sessionToken &&
			sessionToken !== sessionChangedRef.current
		) {
			dispatch(getConversations(memberId, sessionToken));
			sessionChangedRef.current = sessionToken;
		}
	}, [dispatch, memberId, getSessionToken, vendor.name, lastVendorNameSent]);
};
