import { Button } from '@xo-union/tk-component-buttons';
import { Body1 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React, { memo, useRef, type FC } from 'react';
import type VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { useTrackReviewImpression } from './hooks/useTrackReviewImpression';
import Styles from './styles.scss';

interface ReviewPromptProps {
	reviewsCount: number;
	handleClick: () => void;
	vendorAnalytics?: VendorAnalytics;
}

const ReviewPrompt: FC<ReviewPromptProps> = (props) => {
	const { reviewsCount, handleClick, vendorAnalytics } = props;
	const hasNoReviewsMessage =
		'No reviews yet! Be the first couple to share your thoughts!';
	const hasNoReviews = !reviewsCount;

	const ref = useRef(null);
	useTrackReviewImpression(ref, vendorAnalytics);

	return (
		<div ref={ref}>
			{hasNoReviews && (
				<Body1 className={Styles.shareThoughts}>{hasNoReviewsMessage}</Body1>
			)}
			<Button
				className={hasNoReviews ? Styles.buttonWithFilteringNoReviews : ''}
				color="secondary"
				size="md"
				onClick={handleClick}
			>
				Write a review
			</Button>
		</div>
	);
};

ReviewPrompt.propTypes = {
	reviewsCount: PropTypes.number.isRequired,
	handleClick: PropTypes.func.isRequired,
};

export default memo(ReviewPrompt);
