import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import Constants from '../constants';

export const useUpdateAmountToRender = (
	setAmountToRender: Dispatch<SetStateAction<number>>,
	isFetching: boolean,
) => {
	useEffect(() => {
		if (isFetching) {
			setAmountToRender(Constants.INCREMENT_BY);
		}
	}, [isFetching, , setAmountToRender]);
	return useCallback(() => {
		setAmountToRender((prevAmountToRender) => {
			return prevAmountToRender + Constants.INCREMENT_BY;
		});
	}, []);
};
