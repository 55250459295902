import { useViewReviews } from '@components/reviews/hooks/use-view-storefront-reviews';
import React, { type FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import type { State } from 'types/redux';
import type { Decorated } from 'types/vendor';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';
import type { NavItemProps } from '../nav/types';
import Reviews from './components/reviews/Reviews';
import Constants from './constants';
import { useFetchReviews } from './hooks/useFetchReviews';
import { useUpdateAmountToRender } from './hooks/useUpdateAmountToRender';
import { useViewMoreOrFetchReviews } from './hooks/useViewMoreOrFetchReviews';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = NavItemProps & {};

type Props = StateProps & OwnProps;

const ReviewsContainer: FC<Props> = ({ member, vendor }) => {
	const {
		collection,
		isFetching,
		totalVendorReviewsCount,
		totalCount,
		buckets,
		isFetchingMore,
		rating,
	} = useViewReviews();
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);
	const reviewsCount = useMemo(() => collection.length, [collection]);
	const [amountToRender, setAmountToRender] = useState(Constants.INCREMENT_BY);
	const updateAmountToRender = useUpdateAmountToRender(
		setAmountToRender,
		isFetching,
	);
	const fetchReviews = useFetchReviews(
		reviewsCount,
		vendor.id,
		vendor.isPaid,
		updateAmountToRender,
	);
	const viewMoreOrFetchReviews = useViewMoreOrFetchReviews(
		reviewsCount,
		amountToRender,
		vendorAnalytics,
		fetchReviews,
		updateAmountToRender,
	);
	return (
		<Reviews
			buckets={buckets}
			reviews={collection}
			amountToRender={amountToRender}
			reviewsCount={totalCount}
			viewMoreOrFetchReviews={viewMoreOrFetchReviews}
			isFetching={isFetching}
			isFetchingMore={isFetchingMore}
			totalVendorReviewsCount={totalVendorReviewsCount}
			vendorAnalytics={vendorAnalytics}
			vendorId={vendor.id}
			member={member}
			rating={rating}
		/>
	);
};

export function mapStateToProps(state: State) {
	return {
		member: state.membership.member || {},
		vendor: state.vendor.vendor as Decorated,
		isMobile: state.viewport.isMobile,
	};
}

const ReviewsContainerWithRedux = connect<StateProps, object, OwnProps>(
	mapStateToProps,
)(ReviewsContainer);

const ReviewsWithJotai: FC<NavItemProps> = () => <ReviewsContainerWithRedux />;

export default ReviewsWithJotai;
