import { useMemo } from 'react';

export const useSecondaryCTAIndex = (
	totalReviewsCount: number,
	vendorId: string,
) => {
	return useMemo(() => {
		if (totalReviewsCount <= 0) {
			return -1;
		}

		if (totalReviewsCount < 3) {
			return totalReviewsCount - 1;
		}

		return 2;
	}, [totalReviewsCount, vendorId]);
};
