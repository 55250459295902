import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import AnalyticsConstants from '../../../../../../../../constants/analytics';
import type VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

interface OnToggleParams {
	isTruncated: boolean;
}

export const useTrackReviewToggle = (vendorAnalytics?: VendorAnalytics) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(info: OnToggleParams) => {
			const selection = info.isTruncated
				? AnalyticsConstants.READ_LESS
				: AnalyticsConstants.READ_MORE;

			if (vendorAnalytics) {
				track({
					event: AnalyticsConstants.REVIEW_INTERACTION,
					properties: {
						sourcePage: AnalyticsConstants.STOREFRONT,
						selection,
						...vendorAnalytics.baseEventProps(),
					},
				});
			}
		},
		[vendorAnalytics, track],
	);
};
