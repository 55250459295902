import VendorAnalytics from '../../client/decorators/vendorAnalytics';

interface BuildAnalyticsEventParams {
	analyticsEventType: string;
	analyticsProperties: { [key: string]: string };
	vendor: Vendor.Decorated;
}

export const buildAnalyticsEvent = ({
	analyticsEventType,
	analyticsProperties,
	vendor,
}: BuildAnalyticsEventParams) => {
	const vendorAnalytics = new VendorAnalytics(vendor);

	return {
		event: analyticsEventType,
		properties: {
			...analyticsProperties,
			...vendorAnalytics.baseEventProps(),
		},
	};
};
