import { reviewAiSummaryAtom } from '@components/reviews/atoms';
import { useReviewSummaryFilter } from '@components/reviews/hooks/use-review-summary-filter';
import { useStorefrontFeatureReviewsAISummary } from '@feature-flags/hooks/Storefront/useStorefrontFeatureReviewsAISummary';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import type { ReviewBuckets } from '@typings/reviews';
import { Column, Row } from '@xo-union/tk-component-grid';
import ProgressBar from '@xo-union/tk-component-progress-bar';
import { Body1, H2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { type FC, useMemo } from 'react';
import type VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import ReviewStars from '../../../vendor_info/components/review_stars';
import ReviewPrompt from '../review_prompt';
import ReviewAISummary from '../reviews_ai_summary';
import VerifiedReviewsBox from '../verified_reviews_box';
import Styles from './styles.scss';

export interface OwnProps {
	buckets: ReviewBuckets;
	rating: number;
	reviewsCount: number;
	openModal: () => void;
	vendorAnalytics?: VendorAnalytics;
	showVerifiedReviewsMessage?: boolean;
}

type Props = OwnProps;

interface ReviewBucketProps {
	stars: number;
	text: '5 Star' | '4 Star' | '3 Star' | '2 Star' | '1 Star';
	progressBarColor?: string | null;
}

const ReviewBucket: FC<ReviewBucketProps> = ({
	stars,
	text,
	progressBarColor = '#FBAF00',
}) => {
	const pct = useMemo(() => `${(stars * 100).toFixed(0)}%`, [stars]);
	const { filterByReviewSummary } = useReviewSummaryFilter(text);

	return (
		<button
			onClick={filterByReviewSummary}
			type="button"
			className={Styles.reviewBucket}
		>
			<div className={Styles.starText}>{text}</div>
			<div className={Styles.progressBarColumn}>
				<div className={Styles.progressBarWrapper}>
					<ProgressBar progress={stars} color={progressBarColor} />
				</div>
			</div>
			<div className={Styles.pctText}>{pct}</div>
		</button>
	);
};

const ReviewsSummaryContent: FC<Props & { progressBarColor?: string }> = ({
	reviewsCount,
	buckets,
	vendorAnalytics,
	rating,
	openModal,
	progressBarColor,
}) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	return (
		<>
			<Column className={Styles.reviewDetailsItem} xs="12" sm="5" md="4">
				<div className={Styles.reviewDetailsHeader}>
					<div className={Styles.reviewDetailsRating}>
						<Body1 data-testid="review-rating">
							<H2 className={Styles.reviewsDetailsStarCount}>
								{shouldShowScannability ? rating.toFixed(1) : rating}
							</H2>{' '}
							out of 5.0
						</Body1>
						<ReviewStars overallRating={rating} size="lg" />
					</div>
					<Body1 className={Styles.reviewsCountText}>
						{reviewsCount} reviews
					</Body1>
				</div>
				<ReviewPrompt
					reviewsCount={reviewsCount}
					handleClick={openModal}
					vendorAnalytics={vendorAnalytics}
				/>
			</Column>
			<Column className={Styles.reviewDetailsBuckets} xs="12" sm="7" md="8">
				<ReviewBucket
					stars={buckets.stars5}
					text="5 Star"
					progressBarColor={progressBarColor}
				/>
				<ReviewBucket
					stars={buckets.stars4}
					text="4 Star"
					progressBarColor={progressBarColor}
				/>
				<ReviewBucket
					stars={buckets.stars3}
					text="3 Star"
					progressBarColor={progressBarColor}
				/>
				<ReviewBucket
					stars={buckets.stars2}
					text="2 Star"
					progressBarColor={progressBarColor}
				/>
				<ReviewBucket
					stars={buckets.stars1}
					text="1 Star"
					progressBarColor={progressBarColor}
				/>
			</Column>
		</>
	);
};

const ReviewsSummary: FC<Props> = ({
	reviewsCount,
	openModal,
	buckets,
	vendorAnalytics,
	rating,
	showVerifiedReviewsMessage,
}) => {
	const reviewAiSummaryData = useAtomValue(reviewAiSummaryAtom);
	const isLiteStorefront = useLiteStorefront();
	const shouldShowScannability = useStorefrontFeatureScannability();
	const progressBarColor = shouldShowScannability ? '#37393F' : undefined;
	const isReviewsAISummary = useStorefrontFeatureReviewsAISummary();
	const hasReviewSummary = Boolean(
		reviewAiSummaryData?.review_summary &&
			isReviewsAISummary &&
			!isLiteStorefront,
	);

	if (!reviewsCount) {
		return (
			<Row>
				<Column xs="12">
					<ReviewPrompt
						reviewsCount={reviewsCount}
						handleClick={openModal}
						vendorAnalytics={vendorAnalytics}
					/>
				</Column>
			</Row>
		);
	}

	return (
		<>
			<Row className={Styles.reviewDetailRow}>
				{shouldShowScannability ? (
					<div
						className={classNames(Styles.reviewDetailWrapper, {
							[Styles.reviewDetailWrapperComplete]: !showVerifiedReviewsMessage,
						})}
					>
						<ReviewsSummaryContent
							reviewsCount={reviewsCount}
							buckets={buckets}
							vendorAnalytics={vendorAnalytics}
							rating={rating}
							openModal={openModal}
							progressBarColor={progressBarColor}
						/>
					</div>
				) : (
					<ReviewsSummaryContent
						reviewsCount={reviewsCount}
						buckets={buckets}
						vendorAnalytics={vendorAnalytics}
						rating={rating}
						openModal={openModal}
						progressBarColor={progressBarColor}
					/>
				)}
				{showVerifiedReviewsMessage && shouldShowScannability && (
					<VerifiedReviewsBox />
				)}
			</Row>
			{hasReviewSummary && <ReviewAISummary data={reviewAiSummaryData} />}
		</>
	);
};

export default ReviewsSummary;
