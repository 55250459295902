import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import React, { memo } from 'react';
import Styles from './styles.scss';

const VerifiedReviewsBox = memo(() => {
	const impressionRef = useTrackStorefrontImpression('Verified Reviews Box');

	return (
		<div
			className={Styles.verifiedReviewsBox}
			ref={impressionRef}
			data-testid="verified-reviews-box"
		>
			<Icon size="md" name="user_checkmark" className={Styles.icon} />
			<Body2 className={Styles.text}>
				Your trust is our goal. Our community relies on honest reviews to help
				you make those big decisions with ease.
			</Body2>
		</div>
	);
});

export default VerifiedReviewsBox;
