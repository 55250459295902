import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';

import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Decorated } from '@typings/vendor';
import AnalyticsConstants from '../../../../../../../constants/analytics';

import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import Styles from './styles.scss';

export interface OwnProps {
	shouldHide?: boolean;
	title: string;
}

interface StateProps {
	vendor: Decorated | null;
}

type Props = OwnProps & StateProps;

export const NavItem: React.FC<Props> = ({
	shouldHide = false,
	title,
	vendor,
}) => {
	const { track } = useAnalyticsContext();

	const id = `nav${title}`;

	const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.currentTarget.blur();

		const vendorAnalytics = new VendorAnalytics(vendor || undefined);

		track({
			event: AnalyticsConstants.MINI_NAV_INTERACTION,
			properties: {
				selection: title,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	return shouldHide ? null : (
		<li className={Styles.navItem} key={id}>
			<Link
				className={Styles.navLink}
				activeClass={Styles.activeNavLink}
				href={`#${id}`}
				to={id}
				smooth
				spy
				isDynamic
				rel={title}
				// The DefinitelyTyped definition for react-scroll is incorrect and doesn't take an event argument.
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				onClick={onClickHandler}
				offset={-49}
			>
				{title}
			</Link>
		</li>
	);
};

function mapStateToProps(state: Redux.State): StateProps {
	return {
		vendor: state.vendor.vendor,
	};
}

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(NavItem);
