import { useMedia } from '@xo-union/tk-ui-breakpoints';
import { useMemo } from 'react';

interface CarouselSize {
	maxSlideWidth: number;
	minSlideWidth: number;
}

const useCarouselSize = (): CarouselSize => {
	const isXxl = useMedia('(min-width: 1360px)');
	const isXl = useMedia('(min-width: 1192px)');
	const isLg = useMedia('(min-width: 1024px)');

	const screenSizes = [isXxl, isXl, isLg];

	const slideWidths = useMemo(() => {
		if (isXxl.yes) {
			return { minSlideWidth: 1016, maxSlideWidth: 1016 };
		}

		if (isXl.yes) {
			return { minSlideWidth: 766, maxSlideWidth: 766 };
		}

		if (isLg.yes) {
			return { minSlideWidth: 601, maxSlideWidth: 601 };
		}

		return { minSlideWidth: 560, maxSlideWidth: 560 };
	}, screenSizes);

	return slideWidths;
};

export default useCarouselSize;
