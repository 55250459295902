const VENUE_AMENITIES = [
	'31884a04-3757-4d39-a947-8b9d04555a10', // 'Bridal Suite',
	'65868d46-5727-4905-b072-8037ed8a3a2d', // 'Handicap Accessible',
	'67fbbfa9-a916-4ece-beb7-754c2fce9cca', // 'Liability Insurance',
	'76394ba3-5007-43ac-8def-195c47321733', // 'On-Site Accommodations',
	'a81d6093-52e6-4ce2-92b7-4c2e9495f6e0', // 'Indoor',
	'62f601ee-12ab-43d5-bb9d-3dc07103e2ed', // 'Outdoor - Covered',
	'a08cd584-a729-4c79-bcfc-93170d296b9d', // 'Outdoor - Not Covered',
	'61277602-5440-41d8-bdbb-d1698af9e855', // 'Wireless Internet',
];

export default VENUE_AMENITIES;
