import Icon from '@xo-union/tk-component-icons';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-scroll';
import type { Decorated, DecoratedAddress } from 'types/vendor';
import Styles from './styles.scss';

interface AddressProps {
	vendor: Decorated;
	showIcon?: boolean;
	link?: boolean;
	showAddress?: boolean;
}

interface AddressPartsProps {
	address: DecoratedAddress;
	serviceArea?: string;
}

export const AddressMetaData = ({ address }: AddressPartsProps) => {
	return (
		<span
			itemProp="address"
			itemType="https://schema.org/PostalAddress"
			itemScope
		>
			{address.address && (
				<meta itemProp="streetAddress" content={address.address} />
			)}
			{address.city && (
				<meta itemProp="addressLocality" content={address.city} />
			)}
			{address.state && (
				<meta itemProp="addressRegion" content={address.state} />
			)}
			{address.postalCode && (
				<meta itemProp="postalCode" content={address.postalCode} />
			)}
		</span>
	);
};

const Address = (props: AddressProps) => {
	const { serviceArea, address, showIcon, link, showAddress } = {
		...props.vendor,
		...props,
	};

	const addressText = useMemo(() => {
		const addressParts = showAddress
			? [address.address, address.city, address.state]
			: [address.city, address.state];

		if (showAddress && !address.address) {
			return null;
		}

		return addressParts.filter(Boolean).join(', ');
	}, [address, showAddress]);

	const renderContent = useCallback(
		() => (
			<>
				<AddressMetaData address={address} />
				{serviceArea && showAddress ? (
					<span
						itemProp="areaServed"
						itemType="https://schema.org/areaServed"
						className={Styles.addressContainer}
					>
						{showIcon && <Icon name="address" size="sm" />}{' '}
						<span className={Styles.linkUnderline}>{serviceArea}</span>
					</span>
				) : (
					<span className={Styles.addressContainer}>
						{showIcon && <Icon name="address" size="sm" />}{' '}
						<span className={Styles.linkUnderline}>{addressText}</span>
					</span>
				)}
			</>
		),
		[address, serviceArea, showIcon, addressText, showAddress],
	);

	return link ? (
		<Link href="#location" to="location" smooth offset={-50}>
			{renderContent()}
		</Link>
	) : (
		<p className={Styles.address}>{renderContent()}</p>
	);
};

export default Address;
