import React from 'react';
import { Sticky } from 'react-sticky';
import type { NavItemElements } from '../types';
import { NavMenu } from './NavMenu';

interface NavBarProps {
	children: NavItemElements;
}

export const NavBar = ({ children }: NavBarProps) => {
	return (
		<Sticky>
			{({ style, isSticky }) => (
				<NavMenu style={style} isSticky={isSticky}>
					{children}
				</NavMenu>
			)}
		</Sticky>
	);
};
