import { useAppSelector } from '@redux/hooks';
import { useEffect, useRef } from 'react';
import { orderRecentlySeenVendors } from '../helpers/recentlyViewedVendors';

export const useOrderRecentlySeenVendors = () => {
	const rawId = useAppSelector((state) => state.vendor.vendorRaw?.id as string);
	const conversations = useAppSelector(
		(state) => state.messagedVendors.conversations,
	);
	const conversation = conversations[rawId];
	const conversationRef = useRef(conversation);
	const isLeadSent = !!conversation;

	// biome-ignore lint/correctness/useExhaustiveDependencies: onMount
	useEffect(() => {
		orderRecentlySeenVendors(rawId, isLeadSent);
	}, []);

	useEffect(() => {
		if (conversation !== conversationRef.current) {
			orderRecentlySeenVendors(rawId, isLeadSent);
			conversationRef.current = conversation;
		}
	}, [conversation, isLeadSent, rawId]);
};
