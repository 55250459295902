import { compose } from '@xo-union/react-css-modules';
import { DisplayLink } from '@xo-union/tk-ui-links';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './DetailsEmptyState.scss';

interface Props {
	breadcrumbUrl?: string;
	section: 'amenities' | 'guest capacity';
}

export const DetailsEmptyState = ({ breadcrumbUrl, section }: Props) => {
	return (
		<div className={Styles.detailsEmptyState}>
			<Body2 bold>{`No ${section} details yet`}</Body2>
			<div className={Styles.detailsEmptyStateContent}>
				<Body1>
					Looking for the perfect match?{' '}
					<DisplayLink
						href={breadcrumbUrl}
						color="secondary"
						size="md"
						classes={compose({
							'tappable-area': Styles.emptyStateTappable,
						})}
					>
						Continue browsing vendors
					</DisplayLink>
				</Body1>
			</div>
		</div>
	);
};
