import { Column } from '@xo-union/tk-component-grid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AwardItems } from '../../../../components/award-items/AwardItems';
import Styles from './styles.scss';

export default class Awards extends Component {
	static propTypes = {
		vendor: PropTypes.object.isRequired,
		fullWidth: PropTypes.bool,
		carousel: PropTypes.bool,
	};

	render() {
		return (
			<Column
				xs="12"
				md={this.props.fullWidth ? '12' : '6'}
				className={classNames({ [Styles.fullWidth]: this.props.fullWidth })}
			>
				<div
					className={classNames({
						[Styles.awardTitleBold]: this.props.fullWidth,
						[Styles.awardTitle]: !this.props.fullWidth,
					})}
				>
					Awards and Affiliations
				</div>
				<div className={Styles.awards}>
					<AwardItems
						vendor={this.props.vendor}
						carousel={this.props.carousel}
					/>
				</div>
			</Column>
		);
	}
}
