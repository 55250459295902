import useAnalyticsContext from '@hooks/useAnalyticsContext';
import React, { useCallback, useEffect, useState } from 'react';
import AnalyticsConstants from '../../../../../../../../constants/analytics';
import type VendorAnalytics from '../../../../../../../decorators/vendorAnalytics';

const options: IntersectionObserverInit = {
	root: null,
	rootMargin: '0px',
	threshold: 1,
};

export const useTrackReviewImpression = (
	ref: React.MutableRefObject<HTMLDivElement | null>,
	vendorAnalytics?: VendorAnalytics,
) => {
	const [hasTracked, setHasTracked] = useState(false);
	const { track } = useAnalyticsContext();

	const trackCallback = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			if (!hasTracked && entry.intersectionRatio === 1 && vendorAnalytics) {
				setHasTracked(true);
				track({
					event: AnalyticsConstants.REVIEWS_IMPRESSION,
					properties: vendorAnalytics.baseEventProps(),
				});
			}
		},
		[hasTracked, track, vendorAnalytics],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(trackCallback, options);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref, trackCallback]);
};
