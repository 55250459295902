import {
	searchSortFilterAppliedAtom,
	searchSortFilterAtom,
	searchSortFilterReducer,
} from '@components/reviews/components/search-sort-filter/atoms';
import { useAtomValue } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import Constants from '../constants';

export const useFetchReviews = (
	reviewCount: number,
	vendorId: string,
	isPaid: boolean,
	updateAmountToRender: () => void,
) => {
	const searchSortFilterApplied = useAtomValue(searchSortFilterAppliedAtom);
	const [, dispatch] = useReducerAtom(
		searchSortFilterAtom,
		searchSortFilterReducer,
	);

	const nextPage = useMemo(
		() => Math.ceil(reviewCount / Constants.REVIEWS_PER_PAGE + 1),
		[reviewCount],
	);

	return useCallback(() => {
		dispatch({ type: 'fetch-more' });
		updateAmountToRender();
	}, [
		nextPage,
		vendorId,
		isPaid,
		updateAmountToRender,
		searchSortFilterApplied,
		dispatch,
	]);
};
