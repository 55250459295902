import { useMemo } from 'react';

export const useAbbrName = (firstName: string, lastName: string): string => {
	return useMemo(() => {
		if (!firstName) {
			return '';
		}
		const lastInitial = lastName ? ` ${lastName[0]}` : '';
		return `${firstName}${lastInitial}`;
	}, [firstName, lastName]);
};
