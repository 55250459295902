import MoreOrLess from '@xo-union/tk-component-more-or-less';
import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import React, { type FC, useMemo, memo } from 'react';
import Styles from './styles.scss';

export interface Props {
	commenterRole?: string | null;
	createdDate?: string | null;
	text?: string | null;
	minimize?: boolean | null;
}

export const VendorReply: FC<Props> = memo(
	({ createdDate, text, commenterRole, minimize }) => {
		const dateFormatted = useMemo(() => {
			if (!createdDate) {
				return '';
			}
			const date = parseISO(createdDate);

			return format(date, 'MM/dd/yyyy');
		}, [createdDate]);

		if (!text) {
			return null;
		}

		const title = minimize ? (
			<Body2 bold as="span" className={Styles.headingTitle}>
				{commenterRole ?? ''} response
			</Body2>
		) : (
			`Response from the ${commenterRole ?? ''} on ${dateFormatted}`
		);

		const date = minimize ? <span>{dateFormatted}</span> : '';

		return (
			<div
				className={Styles.commentWrapper}
				data-testid="vendor-reply-component"
			>
				<div className={Styles.headingWrapper}>
					{title} {date}
				</div>
				<MoreOrLess
					className={classNames({
						[Styles.commentBody]: !minimize,
						[Styles.commentBodyMinimize]: minimize,
					})}
					content={text}
					charLimit={minimize ? 90 : 244}
				/>
			</div>
		);
	},
);

export default VendorReply;
